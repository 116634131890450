import { session } from '$lib/auth';
import {
  handleErrorWithSentry,
  replayIntegration,
  captureConsoleIntegration,
  httpClientIntegration,
  extraErrorDataIntegration,
  browserTracingIntegration,
} from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { jwtDecode } from 'jwt-decode';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.VERCEL_ENV || 'development',
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  sendDefaultPii: true,
  integrations: [
    browserTracingIntegration(),
    replayIntegration({
      networkDetailAllowUrls: [/.*/],
      networkCaptureBodies: true,
      maskAllText: false,
    }),
    captureConsoleIntegration({
      levels: ['log', 'info', 'warn', 'error'],
    }),
    httpClientIntegration(),
    extraErrorDataIntegration(),
  ],
  beforeSend(event) {
    if (event.exception) {
      event.exception.values?.forEach((exception) => {
        if (exception.mechanism) {
          // escalates error in Sentry
          exception.mechanism.handled = false;
        }
      });
    }
    return event;
  },
  enabled: process.env.NODE_ENV !== 'development',
});

// Add user to Sentry context
if (session.current?.idToken) {
  const { id, email } = jwtDecode<{ id: string; email: string }>(session.current.idToken);
  Sentry.setUser({
    id,
    email,
  });
}

export const handleError = handleErrorWithSentry();
